<template>
  <div class="guide">
    <Header back="返回" title="产品列表" index="首页" titleOne="政策管理" titleTwo="产品列表" beforeTitle="编辑" />
    <div class="content">
      <h1 v-if="!$route.query.id">新增</h1>
      <h1 v-if="$route.query.id">编辑</h1>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="first">
          <Basic />
        </el-tab-pane>
        <el-tab-pane label="模板信息" name="second" v-if="$route.query.id">
          <Template ref="getModuleFile" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import Template from "../../components/policy/productList/addTemplate.vue";
import Basic from "../../components/policy/productList/addBasic.vue";
export default {
  data () {
    return {
      activeName: "first",
      index: 0,
    };
  },
  components: {
    Template,
    Basic,
  },
  created () {
    console.log(this.$route.query.id, 'id')
  },
  methods: {
    handleClick () {
      if (this.activeName == "second") {
        this.$nextTick(() => {
          this.$refs.getModuleFile.handleStep(this.index);
        });
      }
    },
  },
};
</script>

<style scoped="scoped">
div/deep/.el-tabs__content {
  margin-top: 32px;
}
</style>
