<template>
  <!-- 政策管理-产品列表-新增/编辑-模板信息 -->
  <div>
    <el-row class="tem" v-if="!template.name">
      <el-button class="addTem" type="primary" @click="open">新建模板</el-button>
    </el-row>
    <el-row class="tem" v-if="template.name">
      <el-button class="addTem" type="primary" @click="openinfo">编辑模板名称</el-button>
    </el-row>
    <div class="tembox" v-if="template.name">
      <h1 v-if="temNameText">{{ template.name }}</h1>
      <!-- <div class="temdiv" v-if="temNameInfo">
        <el-input v-model="temText" placeholder="请输入内容"></el-input>
        <el-button type="primary" style="margin-left: 20px" @click="opensure">确定</el-button>
      </div>-->
      <el-row>
        <el-button v-if="processName" class="info" type="info" @click="module_info()">快捷排序</el-button>
        <el-button class="info" type="info" @click="addProcess">添加流程</el-button>
      </el-row>
    </div>
    <div class="steps_box" v-if="processName">
      <el-steps :active="active" class="step_hover" simple style="margin-top: 20px">
        <el-step
          v-clickDown="index"
          v-for="(item, index) in module"
          :key="index"
          @click.native="handleStep(index)"
          :title="numToStr('step' + item.sort)"
        ></el-step>
      </el-steps>
    </div>

    <div class="tembox" v-if="template.name">
      <h1 v-if="processText">{{ processName }}</h1>
      <el-row>
        <el-upload
          style="margin-right:10px"
          class="upload-demo"
          ref="imgupload"
          action="string"
          :http-request="httpRequest_module"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
        >
          <el-button v-if="processName" class="info" type="info">添加文件</el-button>
        </el-upload>
        <el-button v-if="processName" class="info" type="info" @click="addFolder()">加文件夹</el-button>
        <el-button v-if="processName" class="info" type="info" @click="processInfo()">编辑名称</el-button>
        <el-button v-if="processName" class="info" type="info" @click="processDelete()">删除流程</el-button>
      </el-row>
    </div>
    <!-- 文件名称 -->
    <div class="process_box" v-if="processName">
      <!-- :props="defaultProps" -->
      <!-- @node-drag-start="handleDragStart"
        @node-drag-enter="handleDragEnter"
        @node-drag-leave="handleDragLeave"
        @node-drag-over="handleDragOver"
        @node-drag-end="handleDragEnd"
        @node-drop="handleDrop" 
        :allow-drop="allowDrop"
      :allow-drag="allowDrag"-->
      <el-tree :data="module_file" node-key="id" default-expand-all draggable>
        <div class="tree-node" slot-scope="{ node, data }">
          <div class="tree-node">
            <span v-if="data.file_name && !node.checked" class="node-tit">{{ data.file_name }}</span>
            <div v-else style="display: inline-block">
              <el-input v-model="data.file_name"></el-input>
              <el-button @click="nosureLable(node, data)" class="save-btn">取消</el-button>
              <el-button style="margin-left:0" @click="saveLable(node, data)" class="save-btn">保存</el-button>
            </div>
            <span class="More">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <i class="el-icon-more"></i>
                </span>
                <el-dropdown-menu slot="dropdown" class="module_menu">
                  <el-dropdown-item @click.native="() => nodeClick(node)">编辑</el-dropdown-item>
                  <!-- v-if="data.children" -->
                  <el-dropdown-item @click.native="() => nodeUpload(node)">
                    <el-upload
                      class="upload-demo upload_file"
                      ref="imgupload"
                      action="string"
                      :http-request="httpRequest"
                      :show-file-list="false"
                      :before-upload="beforeAvatarUpload"
                      :file-list="fileList"
                    >上传</el-upload>
                  </el-dropdown-item>
                  <el-dropdown-item @click.native="() => nodeDownload(node, data)">下载</el-dropdown-item>
                  <!-- <el-dropdown-item @click.native="() => nodeShare(node)"
                    >分享</el-dropdown-item
                  >-->
                  <el-dropdown-item @click.native="() => remove(node, data)">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </div>
        </div>
      </el-tree>
    </div>
    <!-- 编辑流程弹窗 -->
    <el-dialog title="编辑流程" :visible.sync="dialogTableVisible" @close="rowIndex = ''">
      <span>操作说明：鼠标左键拖动Sort，可以调整流程顺序！</span>
      <!-- @cell-click="module_name_info" -->
      <el-table :data="gridData" class="sortable_module" row-key="id">
        <el-table-column property="icon" label="sort" width="150">
          <i class="el-icon-sort"></i>
        </el-table-column>
        <el-table-column width="150" property="sort" label="步骤">
          <template slot-scope="scope">{{'step' + scope.row.sort}}</template>
        </el-table-column>
        <el-table-column label="流程名称" property="name">
          <template slot-scope="scope">
            <!-- <el-input
              ref="input"
              size="small"
              v-if="scope.row.index == rowIndex"
              @blur="info_blur"
              v-model="mod_name_info"
            ></el-input>
            <div v-else>{{ scope.row.name }}</div>-->
            {{ scope.row.name }}
          </template>
        </el-table-column>
      </el-table>

      <div class="sort_box">
        <el-button class="sort_sure" @click="dialogTableVisible = false">关闭</el-button>
        <el-button class="sort_sure" type="primary" @click="module_save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import site from "../../global.vue";
import Sortable from "sortablejs";
import { saveAs } from 'file-saver';
import axios from "axios";
export default {
  // 自动执行点击事件
  directives: {
    clickDown: {
      inserted (el, binding, index) {
        if (binding.value == 0) {
          el.click
        }
      }
    }
  },
  data () {
    return {
      new_module: [],//删除流程后新数组
      file_name: '',
      isActive: 0,
      template_id: '',//自建模板id
      userSite: site.userSite,
      moduleNameInfo: false, //控制流程名称编辑input框
      mod_name_info: "",
      active: 0,
      gridData: [],
      dialogTableVisible: false,
      stepIf: false,
      processName: "",
      // proText: "",
      processText: true,
      // processInfo: false,
      temNameText: true,
      temNameInfo: false,
      temName: "",
      temText: "",
      template: {}, //模板
      module: [], //流程
      process_box: "", //存储文件的盒子
      rowIndex: "",
      showTab: true,
      row_data: "", //点击当前行的数据
      // defaultProps: {
      //   children: "children",
      //   label: "file_name",
      // },
      module_file: [], //步骤--文件存储
      index: 0,
      module_file_id: "", //模块步骤id
      process_file_id: "",
      fileList: [],
      data: [],
      processName_file_name: '',//步骤条点击file_name存储
    };
  },
  created () {
    this.token = localStorage.getItem("token");
    if (this.$route.query.id) {
      this.getData();
    }
    // this.handleStep(this.index);
  },
  methods: {
    nodeUpload (node) {
      this.process_file_id = node.data.id;
    },
    httpRequest_module (param) {
      console.log(param, 8888888, '流程id：', this.module_file_id)
      let file = param.file
      const form = new FormData()
      form.append('file', file);
      form.append('token', this.token);
      form.append('process_module_id', this.module[this.index].id);
      this.upload("/api/policy/add_module_fie", form).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleStep(this.index);
      });
    },
    httpRequest (param) {
      console.log(param, 89798);
      let file = param.file;
      const form = new FormData();
      form.append("file", file);
      form.append("process_file_id", this.process_file_id);
      this.upload("/api/policy/upload_process_file", form).then((res) => {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.handleStep(this.index);
      });
    },
    // 上传文件前的过滤
    beforeAvatarUpload (file) {
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      return isLt20M;
    },
    upload (url, params) {
      return axios
        .post(url, params, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          return new Promise((resolve, reject) => {
            resolve(res);
          });
        })
        .catch((err) => {
          console.log("err:", err);
        });
    },
    //流程编辑保存按钮
    module_save () {
      // this.dialogTableVisible = false
      this.axios
        .post("/api/policy/sort_module", {
          token: this.token,
          sort_data: this.gridData.map((data, index) => {
            return { id: data.id, new_sort: index + 1, name: data.name };
          }),
        })
        .then(() => {
          this.getData();
          this.$message({
            type: "success",
            message: "保存成功",
          });
        });
    },
    //获取当前节点修改checked值
    nodeClick (node) {
      node.checked = true;
    },
    //文件下载
    nodeDownload (node, data) {
      console.log(node, data, "文件下载");
      this.axios
        .get("/api/policy/download", {
          params: {
            process_file_id: data.id,
            responseType: 'blob',
          },
        },
        )
        .then((res) => {
          let url = this.userSite + res.data.url.slice(0, res.data.url.length - 1);
          console.log(url, 'url')
          // let str = new Blob([url], { type: 'text/plain;charset=utf-8' });
          // saveAs(str, `导出文件时的名字.txt`);
          // window.open(url, "_blank");
          if (url.substr(-4) == '.txt') {
            const a = document.createElement('a')
            // element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(url))
            // element.setAttribute('download', url)
            // element.style.display = 'none'
            // element.click()
            let event = new MouseEvent('click')
            a.href = url
            a.target = '_blank'
            a.download = res.name
            a.dispatchEvent(event)
          } else {
            window.open(url, "_blank");
          }
        });
    },
    // 文件删除
    remove (node, data) {
      console.log(node, data, "文件删除");
      this.$confirm("确定删除此项目数据，删除后无法恢复！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          this.axios
            .delete("/api/policy/delete_file", {
              params: {
                token: this.token,
                process_file_id: data.id,
              },
            })
            .then((res) => {
              this.$message({
                type: "success",
                message: res.message,
              });
              // 删除后调用获取方法刷新列表
              this.handleStep(this.index);
            });
        })
        .catch((res) => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    nosureLable (node, data) {
      node.checked = false;
    },
    //保存label  文件名称修改
    saveLable (node, data) {
      console.log(node, data);
      this.axios
        .post("/api/policy/edit_file_name", {
          token: this.token,
          process_file_id: data.id,
          file_name: data.file_name,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: '保存成功',
          });
          node.checked = false;
          // this.getFileData(this.filedataId);
        });
    },
    init () {
      // 就是给 tree 重新赋值一下
      this.module_file = [...this.module_file];
    },
    // number转string
    numToStr (num) {
      num = num.toString();
      return num;
    },
    //流程排序表格拖拽
    module_sort () {
      this.$nextTick(() => {
        const tbody = document.querySelector(".sortable_module tbody");
        console.log(tbody, "流程排序列表");
        var _this = this;
        Sortable.create(tbody, {
          onEnd ({ newIndex, oldIndex }) {
            const currRow = _this.gridData.splice(oldIndex, 1)[0];
            _this.gridData.splice(newIndex, 0, currRow);
            console.log(oldIndex, "oldindex");
          },
        });
      });
    },
    // 编辑流程点击
    module_info () {
      console.log(this.gridData, 'this.gridData');
      this.dialogTableVisible = true;
      this.$nextTick(() => {
        this.module_sort();
      });
    },
    // 排序列表流程名称点击
    // module_name_info (row, column) {
    //   console.log(row, column, "row");
    //   if (column.property == "name") {
    //     console.log("流程名称点击事件");
    //     this.rowIndex = row.index;
    //     this.showTab = false;
    //     this.row_data = row;
    //     this.$nextTick(() => {
    //       this.showTab = true;
    //       // this.$refs.input.focus();
    //       this.mod_name_info = row.name;
    //       console.log(456);
    //     });
    //   }
    // },
    // 排序列表流程名称编辑完成后的失焦
    // info_blur () {
    //   this.moduleNameInfo = false;
    //   this.rowIndex = "";
    //   this.row_data.name = this.mod_name_info;
    //   console.log(this.mod_name_info, this.row_data, "编辑框 内容");
    // },
    // 步骤条点击
    handleStep (index) {
      console.log(index, "步骤条点击")
      this.index = index;
      this.active = index
      this.processName = this.module[index].name;
      this.axios
        .get("/api/policy/get_module_file", {
          params: {
            token: this.token,
            module_id: this.module[index].id,
          },
        })
        .then((res) => {
          this.module_file_id = res.data.id;
          console.log(res.data, "步骤id");
          this.processName_file_name = res.data.file_name
          this.module_file = res.data.children;
          // this.init();
          const module_list = res.data.children
          this.data = module_list
        });
    },
    get_module () {
      this.axios
        .get("/api/policy/get_module", {
          params: {
            token: this.token,
            template_id: this.template_id,
          },
        })
        .then((mod) => {
          console.log(mod, "获取删除后流程信息");
          this.module = mod.data;
          this.new_module = mod.data
        });
    },
    // 获取方法
    getData () {
      this.axios
        .get("/api/policy/get_process_template", {
          params: {
            token: this.token,
            id: this.$route.query.id,
          },
        })
        .then((get) => {
          this.template = get.data;
          this.template_id = get.data.id
          console.log(get, "获取模板信息");
          this.axios
            .get("/api/policy/get_module", {
              params: {
                token: this.token,
                template_id: this.template_id,
              },
            })
            .then((mod) => {
              this.gridData = mod.data.map((item, index) => {
                return { ...item, index: index + 1 };
              });
              this.processName = mod.data[this.index].name;
              console.log(mod, "获取流程信息");
              this.module = mod.data;
            });
        });
    },
    open () {
      this.$prompt("模板名称/产品名称", "新建模板", {
        confirmButtonText: "确定",
      })
        .then((res) => {
          console.log(res);
          this.temName = res.value;
          this.axios
            .post("/api/policy/add_process_template", {
              token: this.token,
              process_name: res.value,
              product_id: this.$route.query.id,
              is_share: "",
            })
            .then((item) => {
              this.getData();
              this.$message({
                type: "success",
                message: item.message,
              });
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //添加文件夹
    addFolder () {
      console.log(this.module, '流程信息')
      this.handleStep(this.index)
      console.log(this.module_file_id, '流程步骤id')
      this.$prompt("文件夹名称", "添加文件夹", {
        confirmButtonText: "确定",
      })
        .then((res) => {
          console.log(res, "添加文件");
          this.stepIf = true;
          this.axios
            .post("/api/policy/add_process_folder", {
              token: this.token,
              folder_name: res.value,
              process_file_id: this.module_file_id,
            })
            .then((item) => {
              this.getData();
              this.$message({
                type: "success",
                message: item.message,
              });
              this.processName = this.processName_file_name
              this.handleStep(this.index)
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //添加流程
    addProcess () {
      this.$prompt("流程名称", "添加流程", {
        confirmButtonText: "确定",
      })
        .then((res) => {
          console.log(res);
          // this.processName = res.value;
          this.stepIf = true;
          // if (res.value == this.processName) {
          //   this.$message.warning('流程名称重复')
          // } else {
          this.axios
            .post("/api/policy/add_process_module", {
              token: this.token,
              module_name: res.value,
              template_id: this.template_id,
            })
            .then((item) => {
              this.getData();
              this.$message({
                type: "success",
                message: item.message,
              });
              // this.handleStep(this.index)
            });
          // }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 流程名称编辑
    processInfo () {
      console.log(this.module[this.index], '9999')
      this.$prompt('请输入名称', '编辑名称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.module[this.index].name
      }).then(({ value }) => {
        this.axios
          .post("/api/policy/edit_module_name", {
            token: this.token,
            file_name: value,
            process_module_id: this.module[this.index].id,
          })
          .then((item) => {
            this.getData();
            this.$message({
              type: "success",
              message: item.message,
            });
          });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消编辑'
        });
      });
    },
    //流程删除 
    processDelete () {
      console.log('删除流程按钮', this.gridData[this.index])
      this.$confirm('此操作将删除当前流程, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          console.log('当前流程id：', this.gridData[this.index].id)
          this.axios
            .delete('/api/policy/delete_module', { params: { process_module_id: this.gridData[this.index].id } }).then(() => {
              if (this.index > 1) {
                this.handleStep(this.index - 1)
                this.active = this.index
              } else {
                this.handleStep(this.index + 1)
                this.active = 0
              }
              this.axios
                .get("/api/policy/get_module", {
                  params: {
                    token: this.token,
                    template_id: this.template_id,
                  },
                })
                .then((mod) => {
                  console.log(mod, "xinxinxin");
                  this.axios
                    .post("/api/policy/sort_module", {
                      token: this.token,
                      sort_data: mod.data.map((data, index) => {
                        return { id: data.id, new_sort: index + 1, name: data.name };
                      }),
                    })
                    .then(() => {
                      this.getData();
                      this.$message({
                        type: "success",
                        message: "排序成功",
                      });
                    });
                });
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            })

        }).catch(() => {
          this.get_module();
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },
    openinfo () {
      // this.temNameText = false;
      // this.temNameInfo = true;
      // this.temText = this.template.name;
      console.log(this.template.name, '888')
      this.$prompt('请输入名称', '编辑模板名称', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: this.template.name
      }).then(({ value }) => {
        this.axios
          .post("/api/policy/add_process_template", {
            token: this.token,
            process_name: value,
            product_id: this.$route.query.id,
            is_share: "",
          })
          .then((item) => {
            this.template.name = value;
            this.$message({
              type: "success",
              message: item.message,
            });
          });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消编辑'
        });
      });
    },
    opensure () {
      this.axios
        .post("/api/policy/add_process_template", {
          token: this.token,
          process_name: this.temText,
          product_id: this.$route.query.id,
          is_share: "",
        })
        .then((item) => {
          this.$message({
            type: "success",
            message: item.message,
          });
        });
      this.temNameText = true;
      this.temNameInfo = false;
      this.template.name = this.temText;
    },
    // handleDragStart(node, ev) {
    //   console.log("drag start", node);
    // },
    // handleDragEnter(draggingNode, dropNode, ev) {
    //   console.log("tree drag enter: ", dropNode.label);
    // },
    // handleDragLeave(draggingNode, dropNode, ev) {
    //   console.log("tree drag leave: ", dropNode.label);
    // },
    // handleDragOver(draggingNode, dropNode, ev) {
    //   console.log("tree drag over: ", dropNode.label);
    // },
    // handleDragEnd(draggingNode, dropNode, dropType, ev) {
    //   console.log("tree drag end: ", dropNode && dropNode.label, dropType);
    // },
    // handleDrop(draggingNode, dropNode, dropType, ev) {
    //   console.log("tree drop: ", dropNode.label, dropType);
    // },
    // allowDrop(draggingNode, dropNode, type) {
    //   if (dropNode.data.label === "二级 3-1") {
    //     return type !== "inner";
    //   } else {
    //     return true;
    //   }
    // },
    // allowDrag(draggingNode) {
    //   return draggingNode.data.label.indexOf("三级 3-2-2") === -1;
    // },
  },
};
</script>

<style scoped>
div/deep/.addTem {
  width: 224px !important;
}
.tem {
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e7ed;
}
.tembox {
  height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info {
  background: #f5f7fa;
  color: #909399;
  border-color: #f5f7fa;
}
.temdiv {
  /* margin-top: 22px; */
  display: flex;
  align-items: center;
}
div/deep/.el-input {
  width: 450px;
}
div/deep/.el-dialog {
  width: 43% !important;
}
.process_box {
  width: 100%;
  height: 500px;
  overflow: auto;
}
div/deep/.el-dialog__title {
  font-weight: bold;
}
.sort_box {
  width: 100%;
  text-align: center;
  margin: 21px 0;
  padding-bottom: 20px;
}
div/deep/.el-step__title {
  cursor: pointer;
}
.el-input {
  width: 100% !important;
}
.el-tree-node__content {
  width: 100%;
}
.tree-node {
  width: 100%;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding-right: 15px;
}

.tree-node .el-input {
  width: 300px;
}

::v-deep .el-tree-node__content {
  height: 40px !important;
}
.ChooseFile {
  border: none;
  text-align: left;
  color: #000;
  display: inline-block;
}

.ChooseFile span {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.el-dropdown-menu__item:hover .ChooseFile {
  background-color: #e7ebff;
  color: #3f60ff;
}
.steps_box {
  width: 100%;
  overflow: auto;
}
.el-steps--simple {
  /* width: 100%; */
  margin-bottom: 20px;
}
div/deep/.el-step {
  flex-basis: initial !important;
}
div/deep/.el-step__title.is-wait {
  color: #000;
  border-color: #000;
}
div/deep/.el-step__head.is-wait {
  color: #000;
  border-color: #000;
}
div/deep/.el-step__title.is-finish {
  color: #000;
}
div/deep/.el-step__head.is-finish {
  color: #000;
  border-color: #000;
}
div/deep/.el-step__title.is-process {
  color: #3f60ff;
  font-size: 18px;
}
div/deep/.el-step__head.is-process {
  color: #3f60ff;
  border-color: #3f60ff;
}
div/deep/.el-step.is-simple {
  min-width: 200px;
}
/* div/deep/.el-step__head {
  display: none;
} */
.finish_module {
  width: 25% !important;
  margin: 0 auto;
}
div/deep/.el-input--suffix {
  width: 440px !important;
}
.el-step.is-simple {
  padding: 13px 0;
  background: #f5f7fa;
}
.el-steps--simple {
  padding: 0 8%;
}
.upload-demo {
  width: 98px;
}
.el-row {
  display: flex;
}
.module_menu {
  width: 80px;
  text-align: center;
}
.upload_file {
  width: 100%;
}
</style>
