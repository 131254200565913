<template>
  <!-- 政策管理-产品列表-新增/编辑-基本信息 -->
  <div>
    <el-form
      :rules="productRule"
      ref="productRule"
      :model="productform"
      label-width="80px"
      :label-position="labelPosition"
    >
      <el-form-item label="产品名称" prop="title">
        <el-input v-model="productform.title" placeholder="请输入产品名称"></el-input>
      </el-form-item>
      <el-form-item label="产品类型" prop="type">
        <el-select v-model="productform.type" placeholder="产品类型">
          <el-option v-for="item in type" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键词">
        <el-input v-model="productform.keywords" placeholder="请输入产品关键词"></el-input>
      </el-form-item>
      <el-form-item label="产品依据">
        <el-input v-model="productform.url" placeholder="请输入产品依据如:www.gaoqibang.cn"></el-input>
      </el-form-item>
      <div class="clear"></div>
      <el-form-item label="所属区域" prop="province_id">
        <el-select v-model="productform.province_id" placeholder="请选择省" @change="getcity">
          <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="city_id">
        <el-select v-model="productform.city_id" placeholder="请选择市" @change="gettown">
          <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="town_id">
        <el-select v-model="productform.town_id" placeholder="请选择区" @change="getitemId">
          <el-option v-for="item in town" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="主管部门">
        <el-input v-model="productform.department" placeholder="请输入主管部门"></el-input>
      </el-form-item>
      <div class="clear"></div>
      <el-form-item label="原文链接">
        <el-input v-model="productform.source_url" placeholder="请输入原文链接"></el-input>
      </el-form-item>
      <div class="clear"></div>
      <el-form-item label="产品描述" style="width: 100%">
        <el-input
          type="textarea"
          v-model="productform.description"
          :autosize="{ minRows: 5, maxRows: 5 }"
          placeholder="请输入产品描述"
        ></el-input>
      </el-form-item>
      <el-form-item label="申请条件" style="width: 100%">
        <el-input
          type="textarea"
          v-model="productform.conditions"
          :autosize="{ minRows: 5, maxRows: 5 }"
          placeholder="请输入申请条件"
        ></el-input>
      </el-form-item>
      <el-form-item label="奖励" style="width: 100%">
        <el-input
          type="textarea"
          v-model="productform.award"
          :autosize="{ minRows: 5, maxRows: 5 }"
          placeholder="请输入奖励"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="foot_TJ">
      <el-button
        type="primary"
        :loading="btnLoading"
        @click="productSubmit"
        :disabled="isDisable"
      >确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      productList: [],//产品列表
      productId: '',//产品id
      productParams: {
        page: 1,
        limit: 10,
        keywords: "",
        province_id: "",
        city_id: "",
        town_id: "",
        order: "id desc",
      },
      isDisable: false,
      labelPosition: "top",
      productform: {
        title: "",
        type: "",
        keywords: "",
        url: "",
        province_id: "",
        city_id: "",
        town_id: "",
        source_url: "",
        description: "",
        conditions: "",
        award: "",
        department: "",
      },
      type: [],
      province: [],
      city: [],
      town: [],
      options: [],
      productRule: {
        title: [
          {
            required: true,
            message: "请输入产品名称",
            trigger: "blur",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择产品类型",
            trigger: "change",
          },
        ],
        province_id: [
          {
            required: true,
            message: "请选择省",
            trigger: "change",
          },
        ],
      },
    };
  },
  created () {
    this.getprovince();
    this.changeType();
    this.getitemId();
    console.log(this.meber_id);
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/policy/product_detail", {
          params: {
            id: this.meber_id,
          },
        })
        .then((res) => {
          res.data.type = parseInt(res.data.type);
          this.productform = res.data;
          this.productform.member_id = this.meber_id;
          this.productform.department = res.data.department;
        });
    }
  },
  watch: {
    "productform.province_id": {
      handler (val, oval) {
        this.getcity(val);
      },
      deep: true,
    },
    "productform.city_id": {
      handler (val, oval) {
        this.gettown(val);
      },
      deep: true,
    },
    "productform.town_id": {
      handler (val, oval) {
        this.getitemId(val);
      },
      deep: true,
    },
  },
  methods: {
    getprovince () {
      this.axios.get("/api/region/getProvince").then((res) => {
        this.province = res.data;
      });
    },
    getcity (id) {
      this.axios
        .get("/api/region/getCity", {
          params: {
            province_id: id,
          },
        })
        .then((res) => {
          this.city = res.data;
        });
    },
    gettown (id) {
      this.axios
        .get("/api/region/getTown", {
          params: {
            city_id: id,
          },
        })
        .then((res) => {
          this.town = res.data;
        });
    },
    changeType () {
      this.axios.get("/api/policy/select_product_type").then((res) => {
        console.log(res);
        this.type = res.data;
      });
    },
    getitemId (id) {
      this.axios
        .get("/api/region/getSection", {
          params: {
            town_id: id,
          },
        })
        .then((res) => {
          this.options = res.data;
        });
    },
    //获取产品列表
    // getProductList () {
    //   this.axios.get('/api/policy/product_list', this.productParams).then((res) => {
    //     this.productList = res.data.data
    //     this.productId = res.data.data[0].id
    //     console.log(this.productId, 'idididid')
    //   })
    // },
    productSubmit () {
      this.$refs.productRule.validate((vaild) => {
        if (vaild) {
          this.btnLoading = true
          this.axios
            .post("/api/policy/store_product", this.productform)
            .then((res) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.btnLoading = false
              this.isDisable = true;
              this.$confirm('保存成功,是否立即添加产品模板信息', '提示', {
                confirmButtonText: '去添加',
                cancelButtonText: '返回产品列表',
                type: 'warning'
              }).then(() => {
                this.axios.get('/api/policy/product_list', this.productParams).then((res) => {
                  this.productId = res.data.data[0].id
                  this.$router.push({ path: "/product/edit", query: { id: this.productId } });
                })
              }).catch(() => {
                this.$router.push("/product/index");
              });
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
        } else {
          this.$message.error("请输入必填项");
        }
      });
    },
  },
};
</script>

<style scoped>
::v-deep .el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}
</style>
